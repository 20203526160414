<template>
  <v-col>
    <default-rule-save-form @created="addToList"/>
    <v-card class="mt-4" flat tile :loading="isLoading">
      <v-card-title>
        <div>{{ $t('pages.game_rules.list_title')}} </div>
      </v-card-title>

      <v-card-text v-if="isError">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-if="defaultRules.length === 0">
        {{ $t('pages.game_rules.no_rules') }}
      </v-card-text>

      <v-card-text v-else-if="isIdle || isLoading">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-left">{{ $t('common.words.event') }}</th>
            <th class="text-left">{{ $t('common.words.linked_reward') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="defaultRule in defaultRules" :key="defaultRule.id">
            <td class="text-left">{{ defaultRule.eventName }}</td>
            <td class="text-left">
              <RewardDefinitionInline :reward-definition="defaultRule.rewardDefinition"/>
            </td>
            <td class="text-right">
              <confirm-button width="400px" @click="remove(defaultRule.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="error"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.game_rules.confirm_rule_deletion_1') }}<br/>
                  {{ $t('pages.game_rules.confirm_rule_deletion_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.game_rules.confirm_rule_deletion_message') }}
                </template>
              </confirm-button>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import GameRuleService from '@/services/gamificator/gamerule.service';
import Paginator from '@/components/util/paginator.vue';

import FetchMixin from '@/mixins/fech.mixin';
import DeleteMixin from '@/mixins/delete.mixin';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import DefaultRuleSaveForm from '@/components/GameRule/DefaultRuleSaveForm.vue';
import RewardDefinitionInline from '@/components/rewardDefinition/RewardDefinitionInline.vue';

export default {
  name: 'defaultRulesView',
  mixins: [FetchMixin, DeleteMixin],
  components: {
    RewardDefinitionInline,
    ConfirmButton,
    Paginator,
    DefaultRuleSaveForm,
  },
  data: () => ({
    defaultRules: [],
  }),
  methods: {
    fetchDefaultRules() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return GameRuleService.fetchRules({ page, size });
    },
    addToList(defaultRule) {
      this.defaultRules = [...this.defaultRules, defaultRule];
    },
    removeFromList(id) {
      this.defaultRules = this.defaultRules.filter((defaultRule) => defaultRule.id !== id);
    },
    deleteDefaultRule(id) {
      return GameRuleService.deleteRule(id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'defaultRules',
      fetch: 'fetchDefaultRules',
    };
    this.deleteMixin.config = {
      delete: 'deleteDefaultRule',
      callback: 'removeFromList',
    };
    this.fetch();
  },
};
</script>

<template>
  <v-card outlined flat>
    <v-card-title v-if="isInCreationMode">
      {{ $t('common.action.create') }} {{ $t('common.messages.a_new_fem') }} {{ $t('common.words.mrule') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="defaultRule" v-model="validDefaultRule">
        <v-text-field v-bind:label="$t('common.labels.event_name')"
                      name="eventName"
                      type="text"
                      v-model="model.eventName"
                      @keypress.enter.prevent="submit"
                      :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mname')})]"/>
      </v-form>
      <v-row class="mb-5 px-5 text-h6">
        {{ $t('pages.leaderboard_definitions.rewards_choice') }} :
      </v-row>
      <v-row class="mb-5 px-5">
        <RewardSelection class="px-5" @selected="updateSelection" :limit=1></RewardSelection>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="isInCreationMode" depressed color="primary" @click="submit"
             :loading="createMixin.state === createMixin.STATES.CREATING"
             :disabled="!validDefaultRule">
        <span>{{ $t('common.action.create') }}</span>
      </v-btn>
      <v-btn v-else depressed color="primary" @click="submit"
             :loading="updateMixin.state === updateMixin.STATES.CREATING"
             :disabled="!validDefaultRule">
        <span>{{ $t('common.action.modify') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import GameRuleService from '@/services/gamificator/gamerule.service';
import RewardSelection from '@/components/rewardDefinition/RewardSelection.vue';

export default {
  name: 'defaultRule-creation-defaultRule',
  components: { RewardSelection },
  mixins: [CreateMixin, UpdateMixin],
  props: ['value'],
  data: () => ({
    model: {},
    validDefaultRule: false,
  }),
  watch: {
    value() {
      this.model = { ...this.value };
    },
  },
  methods: {
    updateSelection(selection) {
      this.$set(this.model, 'rewardId', selection[0]);
    },
    submit() {
      this.$refs.defaultRule.validate();
      if (!this.validDefaultRule || this.model.rewardId === undefined) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    createDefaultRule() {
      return GameRuleService.createRule(this.model);
    },
    updateDefaultRule() {
      const { id, ...rest } = this.model;
      return GameRuleService.updateRule(id, rest);
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createDefaultRule',
    };
    this.updateMixin.config = {
      update: 'updateDefaultRule',
    };
    this.model = { ...this.value };
  },

};
</script>
